exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogo-js": () => import("./../../../src/pages/catalogo.js" /* webpackChunkName: "component---src-pages-catalogo-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-envios-js": () => import("./../../../src/pages/envios.js" /* webpackChunkName: "component---src-pages-envios-js" */),
  "component---src-pages-formas-de-pago-js": () => import("./../../../src/pages/formas-de-pago.js" /* webpackChunkName: "component---src-pages-formas-de-pago-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-turismo-y-mas-js": () => import("./../../../src/pages/turismo-y-mas.js" /* webpackChunkName: "component---src-pages-turismo-y-mas-js" */)
}

